<template>
  <div>
    <titulo-relatorio-ficha-socioeconomica />
    <b-row>
      <b-col md="3">
        <input-select-search
          ref="anoLetivoId"
          v-model="filtro.anoLetivoId"
          :label="$t(`ACADEMICO.PERIODO_LETIVO`)"
          :options="opcoes.anosLetivos"
          required
        />
      </b-col>
      <b-col md="3">
        <input-select-search
          ref="cursoId"
          v-model="filtro.cursoId"
          :label="$t(`ACADEMICO.CURSO`)"
          :options="opcoes.cursos"
          :isDisabled="desabilitar.curso"
          required
        />
      </b-col>
      <b-col md="3">
        <input-select-search
          ref="grupoTurmaId"
          v-model="filtro.grupoTurmaId"
          :label="$t(`ACADEMICO.GRUPO_TURMA`)"
          :options="opcoes.grupoTurmas"
          :isDisabled="desabilitar.grupoTurma"
        />
      </b-col>
      <b-col md="3">
        <input-select-search
          ref="turmaId"
          v-model="filtro.turmaId"
          :label="$t(`ACADEMICO.TURMA`)"
          :options="opcoes.turmas"
          :isDisabled="desabilitar.turma"
        />
      </b-col>
      <b-col md="3">
        <input-select-search
          ref="descontoId"
          v-model="filtro.descontoId"
          :label="$t(`ACADEMICO_MATRICULAS.DESCONTO`)"
          :options="opcoes.descontos"
        />
      </b-col>
      <b-col cols="12" md="auto" order-md="1" class="mb-3 mb-md-0">
        <b-button
          variant="secondary"
          class="botao-acao-filtro w-100"
          @click="limparFiltro"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-button>
      </b-col>
      <b-col cols="12" md="auto" order-md="1" class="mb-3 mb-md-0">
        <b-button
          variant="info"
          class="botao-acao-filtro w-100"
          @click="gerarFichaSocioeconomica"
        >
          {{ $t('ACADEMICO.GERAR_FICHA_SOCIOECONOMICA') }}
        </b-button>
      </b-col>
      <b-col cols="12" md="auto" order-md="1" class="mb-3 mb-md-0">
        <b-button
          variant="info"
          class="botao-acao-filtro w-100"
          @click="gerarEtiquetas"
        >
          {{ $t('ACADEMICO.GERAR_ETIQUETAS') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import breadcrumb from '@/common/utils/breadcrumb';
import pdf from '@/common/utils/pdf';

// Services:
import ContratoService from '@/common/services/contratos/contratos.service';
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import CursoService from '@/common/services/academico/curso.service';
import TurmaService from '@/common/services/academico/turma.service';
import GrupoTurmaService from '@/common/services/grupoTurma/grupo-turma.service';
import DescontoService from '@/common/services/descontos/descontos.service';

// Components:
import TituloRelatorioFichaSocioeconomica from '@/views/academico/relatorios/relatorio-ficha-socioeconomica/TituloRelatorioFichaSocioeconomica';
import { InputSelectSearch } from '@/components/inputs';

export default {
  name: 'relatorioFichaSocioeconomia',
  components: {
    InputSelectSearch,
    TituloRelatorioFichaSocioeconomica,
  },
  data() {
    return {
      desabilitar: {
        turma: true,
        grupoTurma: true,
        curso: true,
      },
      filtro: {
        anoLetivoId: '',
        cursoId: '',
        grupoTurmaId: '',
        turmaId: '',
        descontoId: '',
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        grupoTurmas: [],
        turmas: [],
        descontos: [],
      },
    };
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('ACADEMICO.ACADEMICO') },
      { titulo: this.$t('ACADEMICO.RELATORIOS') },
      { titulo: this.$t('ACADEMICO.FICHA_SOCIOECONOMICA') },
    ]);

    this.getAnosLetivos();
    this.getDescontos();
  },
  watch: {
    'filtro.anoLetivoId': {
      handler(anoLetivoId) {
        if (anoLetivoId) {
          this.getCursos(anoLetivoId);
        } else {
          this.opcoes.cursos = [];
          this.opcoes.gruposTurma = [];
          this.opcoes.turmas = [];
          this.desabilitar.curso = true;
          this.desabilitar.grupoTurma = true;
          this.desabilitar.turma = true;
        }
        this.filtro.cursoId = null;
        this.filtro.grupoTurmaId = null;
        this.filtro.turmaId = null;
      },
    },
    'filtro.cursoId': {
      handler(cursoId) {
        if (cursoId) {
          this.getGrupoTurmas(cursoId);
        } else {
          this.desabilitar.grupoTurma = true;
          this.desabilitar.turma = true;
          this.opcoes.gruposTurma = [];
          this.opcoes.turmas = [];
        }
        this.filtro.grupoTurmaId = null;
        this.filtro.turmaId = null;
      },
    },
    'filtro.grupoTurmaId': {
      handler(turmaId) {
        if (turmaId) {
          this.getTurmasPorCursoGrupoTurma();
        } else {
          this.desabilitar.turma = true;
          this.opcoes.turmas = [];
        }
        this.filtro.turmaId = null;
      },
    },
    // 'filtro.cursoId': {
    //   handler(newValue) {
    //     if (newValue !== undefined) {
    //       this.getGrupoTurmas();
    //     }
    //   },
    //   deep: true,
    // },
    // 'filtro.grupoTurmaId': {
    //   handler(newValue) {
    //     if (newValue !== undefined) {
    //       this.getTurmasPorCursoGrupoTurma();
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    getDescontos() {
      this.$store.dispatch(START_LOADING);
      DescontoService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.descontos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.ano,
              value: row.id,
            };
          });

          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getCursos(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
          this.desabilitar.curso = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getGrupoTurmas() {
      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.buscarPorCursoIdAnoLetivoId(
        this.filtro.cursoId,
        this.filtro.anoLetivoId
      )
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.grupoTurmas = data;

          this.desabilitar.grupoTurma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    getTurmasPorCursoGrupoTurma() {
      this.$store.dispatch(START_LOADING);
      TurmaService.buscarPorCursoIdGrupoTurmaId(this.filtro)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.turmas = data;
          this.desabilitar.turma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },

    limparFiltro() {
      this.filtro = {
        anoLetivoId: '',
        cursoId: '',
        grupoTurmaId: '',
        turmaId: '',
        descontoId: '',
      };
    },

    // FUNÇÕES RELATORIO
    gerarFichaSocioeconomica() {
      if (!this.validarFiltro()) return;
      this.$store.dispatch(START_LOADING);
      ContratoService.gerarFichaSocioEconomica({
        anoLetivoId: this.filtro.anoLetivoId,
        turmaId: this.filtro.turmaId,
        descontoId: this.filtro.descontoId,
        cursoId: this.filtro.cursoId,
      })
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    gerarEtiquetas() {
      if (!this.validarFiltro()) return;
      this.$store.dispatch(START_LOADING);
      ContratoService.gerarEtiquetasSocioeconomica({
        anoLetivoId: this.filtro.anoLetivoId,
        turmaId: this.filtro.turmaId,
        descontoId: this.filtro.descontoId,
        cursoId: this.filtro.cursoId,
      })
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
