<template>
  <topbar
    :titulo="$t('ACADEMICO.FICHA_SOCIOECONOMICA')"
    :subtitulo="$t('ACADEMICO.FICHA_SOCIOECONOMICA_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  />
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.ACADEMICO') },
        { titulo: this.$t('ACADEMICO.RELATORIOS') },
        { titulo: this.$t('ACADEMICO.FICHA_SOCIOECONOMICA') },
      ],
    };
  },
  methods: {},
};
</script>
